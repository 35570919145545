<template>
  <div class="card">
    <div class="card-body pt-4">
      <div class="flex flex-col gap-4">
        <div class="flex flex-col justify-start gap-4">
          <div class="relative w-max">
            <img
              :src="profile.profilePick"
              class="flex-none w-16 h-16 md:w-24 md:h-24 rounded-full object-cover shadow-lg"
            />
            <div class="absolute -right-1 -bottom-1">
              <profile-image-selector-modal
                @update:profile-image="updateProfileImage"
              />
            </div>
          </div>
          <div class="w-full">
            <input
              type="text"
              id="profile-name-input"
              name="name"
              autocomplete="off"
              v-model="profile.name"
              :placeholder="$t('form.profile_name.placeholder')"
              :class="{'input-error': !!currentErrors?.name}"
              class="px-0 py-2"
            >
            <div v-if="!!currentErrors?.name" class="text-sm text-red-700 block">{{ currentErrors?.name }}</div>
          </div>
        </div>

        <div class="text-gray-600">
          Plano <span class="font-semibold">{{ subscription.plan }}</span>
          <span class="mx-2"> &bull; </span>
          {{ subscription.active ? 'Ativo' : 'Suspenso' }}
        </div>

        <div class="flex gap-2">
          <div
            class="select-button"
            v-for="item in socialButtons"
            :key="item.source"
            :style="item.style"
            :class="{'active': item.available}"
          >
            <i
              class="fab text-white"
              :class="item.class"
            >
            </i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// eslint-disable-next-line no-unused-vars
import { useProfile } from '@/composables/profile/useProfile'
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'
import { computed, defineComponent, ref, watch } from 'vue'
import ProfileImageSelectorModal from './ProfileImageSelectorModal.vue'

export default defineComponent({
  components: { ProfileImageSelectorModal },

  props: {
    form: {
      type: Object,
      required: true
    },
    currentErrors: {
      type: Object,
      default: () => {}
    }
  },

  emits: [
    'update:form'
  ],

  setup (props, { emit }) {
    const { profile: currentProfile } = useProfile()
    const {plan} = currentProfile.value

    const profile = ref<any>({
      name: props.form.name,
      website: props.form.website,
      profilePick: props.form.profilePick
    })

    const {
      socialIcons
    } = useSocialAccounts()

    const socialButtons = computed(() => {
      return Object.entries(socialIcons.value)
      .reduce((acc, [key, value]) => {
        // Verifica se a classe já existe no acumulador
        if (!acc.some((item: any) => item.class === value.class)) {
          // Se não existir, adiciona o novo item
          acc.push({
            source: key,
            ...value
          });
        }
        return acc;
      }, [] as any)
    })

    watch(profile, (newValue) => {
      emit('update:form', {
        ...newValue
      })
    }, { deep: true })

    const updateProfileImage = (profileImageUrl: string) => {
      profile.value.profilePick = profileImageUrl
    }

    const subscription = computed(() => {
      return {
        active: plan.active,
        plan: plan.name,
        billingDay: plan.billingDay
      }
    })

    return {
      profile,
      socialButtons,
      subscription,
      updateProfileImage,
    }
  }
})
</script>

<style scoped>
@layer components{
  #profile-name-input {
    @apply bg-black bg-opacity-0 focus:bg-opacity-5 text-2xl placeholder-opacity-80 placeholder-gray-400 font-semibold shadow-none h-full w-full !important;
  }
  #profile-name-input .input-error {
    @apply bg-opacity-5 border border-red-700 !important;
  }

  #profile-name-input:not(.input-error) {
    @apply border border-transparent !important;
  }

  .select-button {
    @apply flex justify-center items-center rounded-full w-6 h-6;
    @apply transform duration-300 ease-in-out;
    @apply focus:outline-none;
  }
  .select-button.active {
    @apply shadow-md;
  }

  .select-button:not(.active) {
      @apply bg-gray-300 !important;
    }

  .select-button:disabled {
    @apply cursor-not-allowed;
    @apply bg-gray-200 !important;
  }
}
</style>