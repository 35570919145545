<template>
  <div class="card">
    <div class="card-body pt-4">
      <div class="flex flex-col gap-4">
        <div class="flex flex-col justify-start gap-4">
          <div class="relative w-max">
            <img
              :src="profile.profilePick"
              class="flex-none w-16 h-16 md:w-24 md:h-24 rounded-full object-cover shadow-lg"
            />
            <div class="absolute -right-1 -bottom-1">
              <profile-image-selector-modal
                @update:profile-image="updateProfileImage"
              />
            </div>
          </div>
          <div class="w-full">
            <input
              type="text"
              id="profile-name-input"
              name="name"
              autocomplete="off"
              v-model="profile.name"
              :placeholder="$t('form.profile_name.placeholder')"
              :class="{'input-error': !!currentErrors?.name}"
              class="px-0 py-2"
            >
            <div v-if="!!currentErrors?.name" class="text-sm text-red-700 block">{{ currentErrors?.name }}</div>
          </div>
        </div>

        <div class="text-gray-600">
          Plano <span class="font-semibold">{{ subscription.plan }}</span>
          <span class="mx-2"> &bull; </span>
          {{ subscription.active ? 'Ativo' : 'Suspenso' }}
        </div>

        <div class="flex gap-2">
          <div
            class="select-button"
            v-for="item in socialButtons"
            :key="item.source"
            :style="item.style"
            :class="{'active': item.available}"
          >
            <i
              class="fab text-white"
              :class="item.class"
            >
            </i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// eslint-disable-next-line no-unused-vars
import { useProfile } from '@/composables/profile/useProfile'
import { useSocialAccounts } from '@/composables/socialAccounts/useSocialAccounts'
import { computed, defineComponent, ref, watch } from 'vue'
import ProfileImageSelectorModal from './ProfileImageSelectorModal.vue'

export default defineComponent({
  components: { ProfileImageSelectorModal },

  props: {
    form: {
      type: Object,
      required: true
    },
    currentErrors: {
      type: Object,
      default: () => {}
    }
  },

  emits: [
    'update:form'
  ],

  setup (props, { emit }) {
    const { profile: currentProfile } = useProfile()
    const {plan} = currentProfile.value

    const profile = ref<any>({
      name: props.form.name,
      website: props.form.website,
      profilePick: props.form.profilePick
    })

    const {
      socialIcons
    } = useSocialAccounts()

    const socialButtons = computed(() => {
      return Object.entries(socialIcons.value)
      .reduce((acc, [key, value]) => {
        // Verifica se a classe já existe no acumulador
        if (!acc.some((item: any) => item.class === value.class)) {
          // Se não existir, adiciona o novo item
          acc.push({
            source: key,
            ...value
          });
        }
        return acc;
      }, [] as any)
    })

    watch(profile, (newValue) => {
      emit('update:form', {
        ...newValue
      })
    }, { deep: true })

    const updateProfileImage = (profileImageUrl: string) => {
      profile.value.profilePick = profileImageUrl
    }

    const subscription = computed(() => {
      return {
        active: plan.active,
        plan: plan.name,
        billingDay: plan.billingDay
      }
    })

    return {
      profile,
      socialButtons,
      subscription,
      updateProfileImage,
    }
  }
})
</script>

<style scoped>
#profile-name-input {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity)) !important;
  --tw-bg-opacity: 0 !important;
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: 600 !important;
  --tw-shadow: none !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  height: 100% !important;
  width: 100% !important;
}
#profile-name-input:focus {
  --tw-bg-opacity: 0.05 !important;
}
#profile-name-input::-webkit-input-placeholder {
  --tw-placeholder-opacity: 0.8 !important;
  --tw-placeholder-opacity: 1 !important;
  color: rgba(161, 161, 170, var(--tw-placeholder-opacity)) !important;
}
#profile-name-input::-moz-placeholder {
  --tw-placeholder-opacity: 0.8 !important;
  --tw-placeholder-opacity: 1 !important;
  color: rgba(161, 161, 170, var(--tw-placeholder-opacity)) !important;
}
#profile-name-input:-ms-input-placeholder {
  --tw-placeholder-opacity: 0.8 !important;
  --tw-placeholder-opacity: 1 !important;
  color: rgba(161, 161, 170, var(--tw-placeholder-opacity)) !important;
}
#profile-name-input::-ms-input-placeholder {
  --tw-placeholder-opacity: 0.8 !important;
  --tw-placeholder-opacity: 1 !important;
  color: rgba(161, 161, 170, var(--tw-placeholder-opacity)) !important;
}
#profile-name-input::placeholder {
  --tw-placeholder-opacity: 0.8 !important;
  --tw-placeholder-opacity: 1 !important;
  color: rgba(161, 161, 170, var(--tw-placeholder-opacity)) !important;
}
#profile-name-input .input-error {
  --tw-bg-opacity: 0.05 !important;
  border-width: 1px !important;
  --tw-border-opacity: 1 !important;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity)) !important;
}
#profile-name-input:not(.input-error) {
  border-width: 1px !important;
  border-color: transparent !important;
}
.select-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 9999px;
  width: 1.5rem;
  height: 1.5rem;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -ms-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  -webkit-transition-duration: 300ms;
  -o-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.select-button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.select-button.active {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.select-button:not(.active) {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(212, 212, 216, var(--tw-bg-opacity)) !important;
}
.select-button:disabled {
  cursor: not-allowed;
  --tw-bg-opacity: 1 !important;
  background-color: rgba(228, 228, 231, var(--tw-bg-opacity)) !important;
}
</style>